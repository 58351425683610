import React from "react"
import { ThemeProvider } from "styled-components"
import * as PropTypes from "prop-types"
import { graphql } from "gatsby"

import Head from "../../components/Head"
import GlobalStyle from "../../styles/globalStyle"

import NotAvailable from "../../components/NotAvailable"

import brandTheme from "../theme"

const NotAvailableTemplate = ({ data: { contentfulGenericContent: data, contentfulBrandSettings: defaultData } }) => {
  const notAvailableText = data.notAvailableText || defaultData.notAvailableText

  return (
    <ThemeProvider theme={brandTheme}>
      <Head title="" />
      <GlobalStyle />
      <NotAvailable data={notAvailableText} />
    </ThemeProvider>
  )
}

NotAvailableTemplate.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired
}

export default NotAvailableTemplate

export const pageQuery = graphql`
  query NotAvailableQuery($language: String) {
    contentfulGenericContent(language: { eq: $language }) {
      language
      notAvailableText {
        childMarkdownRemark {
          html
        }
      }
    }
    contentfulBrandSettings {
      notAvailableText {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
