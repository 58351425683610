import React from "react"
import PropTypes from "prop-types"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"

import LeoVegasLogo from "../../assets/leovegas-casino-logo.png"

import * as S from "./style"

const NotAvailable = ({ data }) => (
  <S.Container>
    <S.Content>
      <img alt="logo" src={LeoVegasLogo} width="200" height="50" />
      {data && <ReactMarkdown rehypePlugins={[rehypeRaw]}>{data.childMarkdownRemark.html}</ReactMarkdown>}
    </S.Content>
  </S.Container>
)

NotAvailable.propTypes = {
  data: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      html: PropTypes.string
    })
  })
}

NotAvailable.defaultProps = {
  data: null
}

export default NotAvailable
