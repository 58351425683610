import styled from "styled-components"

export const Container = styled.div`
  background: linear-gradient(0deg, #f18825, #fd5c3c);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  overflow: scroll;
`

export const Content = styled.div`
  position: relative;
  width: calc(100% - 32px - 32px);
  height: auto;
  max-width: 375px;
  background-color: #fff;
  border-radius: 12px;
  min-width: 300px;
  padding: 16px;
  text-align: center;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 24%);

  p {
    font-size: 1.3em;
    line-height: 1.3em;
    font-family: "Roboto Slab", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 500;
    margin-top: 25px;
  }
`
